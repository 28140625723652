<template>
  <default-layout>
    <panel>
      <table v-table:info v-cols="[25, 75]">
        <thead>
          <tr>
            <th>key</th>
            <th>value</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, key) in info" :key="key">
            <td>
              {{key}}
            </td>
            <td>
              {{value}}
            </td>
          </tr>
        </tbody>
      </table>
    </panel>
  </default-layout>
</template>

<script>
import {Service} from './_resource';

export default {
  data() {
    return {
      info: null
    };
  },
  created() {
    Service.getInfo().then(data => {
      this.info = data;
    }, () => {});
  }
};
</script>
